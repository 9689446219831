<template>
    <div>
        <v-main>
            <v-container class="mt-md-6 mb-16 px-0 px-sm-3">
                <div class="text-h3">Coaching</div>
                <div v-if="!currentGroupId" class="mt-6">
                    <div v-for="(coachingGroup, i) in coachingGroups" :key="i">
                        <v-btn @click="currentGroupId = coachingGroup['id']" link text>
                            {{ "Gruppe " + coachingGroup["id"] }}
                        </v-btn>
                    </div>
                </div>
                <div v-else>
                    <v-btn v-if="coachingGroups.length > 1" @click="currentGroupId = null" class="mt-4">
                        <v-icon left>mdi-arrow-left</v-icon>
                        Zurück zur Gruppenübersicht
                    </v-btn>
                    <div class="text-h4 mt-6">Sessions</div>
                    <div v-if="currentSessionIndex === null" class="mt-3">
                        <div v-for="(session, i) in currentGroup['sessions']" :key="i">
                            <v-btn @click="currentSessionIndex = i" link text class="mb-2 text-none">
                                <span class="mr-2">{{ "#" + (i + 1) }}</span>
                                <span class="mr-1">{{ session["date"] | formatDate }}</span>
                                <span>{{ "- \"" + session["title"] + "\"" }}</span>
                            </v-btn>
                        </div>
                    </div>
                    <div v-else>
                        <v-btn @click="currentSessionIndex = null" class="mt-3">
                            <v-icon left>mdi-arrow-left</v-icon>
                            Zurück zur Sitzungsübersicht
                        </v-btn>
                        <div class="text-h5 mt-6 mb-4">{{ currentSession["date"] | formatDate }}{{ " - " + currentSession["title"] }}</div>
                        <vimeo-player ref="player" :video-id="currentSession['videoId']" :options="{ dnt: true }" :player-height="320" :player-width="568"></vimeo-player>
                        <div class="text-h5 mt-6 mb-4">Zusammenfassung</div>
                        <div style="white-space: pre-line;" class="text-body-1 mt-4" v-text="currentSession['description']"></div>
                        <div class="text-h5 mt-6 mb-4">Inhalte</div>
                        <div v-for="(content, i) in currentSessionContent" :key="i">
                            <div class="d-flex flex-row align-baseline mb-4">
                                <div style="letter-spacing: 0.04em; font-weight: bolder">{{ content['start'] | secondsToTimestamp }}</div>
                                <div class="d-flex flex-column ml-3">
                                    <div class="d-flex flex-row">
                                        <v-chip v-for="speaker in content['speakers']" small outlined>
                                            <span class="text-subtitle-2">{{ speaker }}</span>
                                        </v-chip>
                                    </div>
                                    <div class="mt-1" style="white-space: pre-line;" v-text="content['summary']"></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </v-container>
        </v-main>
    </div>
</template>

<script>

export default {
    name: "Coaching",
    components: { },
    data: () => ({
        coachingGroups: [],
        currentGroupId: null,
        currentSessionIndex: null
    }),
    computed: {
        currentGroup() {
            return this.coachingGroups.find(group => group["id"] === this.currentGroupId);
        },
        currentSession() { // Grundsätze der Selbstführung
            return this.currentGroup["sessions"][this.currentSessionIndex];
        },
        currentSessionContent() {
            return JSON.parse(this.currentSession["content"]);
        }
    },
    filters: {
        formatDate(value) {
            return new Date(value).toLocaleDateString("de-DE", {
                year: "numeric",
                month: "long",
                day: "numeric"
            });
        },
        secondsToTimestamp(seconds) {
            seconds = Math.round(seconds)
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds - (hours * 3600)) / 60);
            const remainingSeconds = seconds - (hours * 3600) - (minutes * 60);

            const paddedHours = String(hours).padStart(2, '0');
            const paddedMinutes = String(minutes).padStart(2, '0');
            const paddedSeconds = String(remainingSeconds).padStart(2, '0');

            return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
        }
    },
    mounted() {
        this.$store.dispatch("getRequestPromise", "coaching").then((data) => {
            this.coachingGroups = data["groups"]
            if (this.coachingGroups.length === 1) {
                this.currentGroupId = this.coachingGroups[0]["id"];
            }
        });
    },
    methods: {

    }
};
</script>

<style scoped>
@media (min-width: 1264px) {
    .container {
        max-width: 1185px;
    }
}
</style>